import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Loading, Spinner } from "@shopify/polaris";
import { Link, Text } from "@shopify/polaris";
import i18n from "i18next";
import "@/AppStyle.scss";
import FallbackUIComponent from "@/Components/Common/FallbackUIComponent";
import { OnboardingContextProvider } from "@/Context/OnboardingContext";
import { ProfileContextProvider } from "@/Context/ProfileContext";
import { ProfileContext } from "@/Context/ProfileContext";
import { ReviewModalContextProvider } from "@/Context/ReviewContext";
import { AppBridgeProvider, ContextProviderList, PolarisProvider } from "@/Providers/Index";
import { ErrorBoundary } from "@/Services/Logger/Sentry";
import "@/Style.scss";
import { getLocalStorageItem } from "@/Utils/Index";
import AdminRouteWrapper from "./AdminRouteWrapper";
import Routes from "./Routes";

const CrispChat = lazy(() => import("@/Components/Common/Crisp"));
const CustomerlyUpdate = lazy(() => import("@/Components/Common/CustomerlyUpdate"));
const PublicRouteWrapper = lazy(() => import("./PublicRouteWrapper"));
// const AdminRouteWrapper = lazy(() => import("./AdminRouteWrapper"));
const initialLanguage = getLocalStorageItem("appLanguage") || "en";

export default function App() {
  const isPublicRoute = window.location.pathname.includes("/public");
  const isAdminRoute = window.location.pathname.includes("/admin");
  const [showChat, setShowChat] = useState(false);

  const ChatBox = () => {
    if (process.env.ENV == "prod") {
      return (
        <div className="open-crisp--chat">
          <Text variant="bodyMd" as="p">
            Need any help ?{" "}
            <Link
              onClick={() => {
                $crisp.push(["do", "chat:open"]),
                  $crisp.push(["do", "message:send", ["text", "Hello, I need a Help "]]);
              }}
              removeUnderline
            >
              Chat With Us
            </Link>
          </Text>
        </div>
      );
    }
    return null;
  };

  const WaitBox = () => {
    const { isProfileLoadding } = useContext(ProfileContext);
    return (
      <>
        {isProfileLoadding && (
          <div className="waitbox flex flex-column justify-content ">
            <Spinner />
            <Text variant="bodyMd" as="p">
              Please Wait While We are Fetching your Data.
            </Text>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChat(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    import(`@/LanguageJS/${initialLanguage}.js`)
      .then((data) => {
        i18n.use(initReactI18next).init({
          lng: initialLanguage,
          fallbackLng: "en",
          interpolation: {
            escapeValue: false,
          },
          resources: {
            [initialLanguage]: {
              translation: data.default,
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error loading initial language file:", error);
        i18n.use(initReactI18next).init({
          lng: "en",
          fallbackLng: "en",
          interpolation: {
            escapeValue: false,
          },
        });
      });
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUIComponent />}>
      <PolarisProvider>
        <ContextProviderList>
          <BrowserRouter>
            {isPublicRoute && !isAdminRoute ? (
              <Suspense fallback={<Loading />}>
                <PublicRouteWrapper />
              </Suspense>
            ) : isAdminRoute ? (
              <Suspense fallback={<Loading />}>
                <AdminRouteWrapper />
              </Suspense>
            ) : (
              <AppBridgeProvider>
                <ProfileContextProvider>
                  <WaitBox />
                  <OnboardingContextProvider>
                    <ReviewModalContextProvider>
                      <Routes />
                      <Suspense>
                        {showChat && (process.env.CSTOMERLY_WEBSITE_ID ? <CustomerlyUpdate /> : <CrispChat />)}
                      </Suspense>
                    </ReviewModalContextProvider>
                  </OnboardingContextProvider>
                  <ChatBox />
                </ProfileContextProvider>
              </AppBridgeProvider>
            )}
          </BrowserRouter>
        </ContextProviderList>
      </PolarisProvider>
    </ErrorBoundary>
  );
}
