import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { BlockStack, InlineError } from "@shopify/polaris";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "./../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorComponent = (props) => {
  const {
    error,
    config,
    value,
    form: { setFieldValue },
    field: { name, label, toolbar },
  } = props;

  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  });
  // https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp

  useEffect(() => {
    const currentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (currentHtml !== value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [value]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setFieldValue(name, draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <BlockStack gap="200">
      <span>{label}</span>
      <div className={error ? "editor-error-field" : ""}>
        <Editor
          editorClassName="editor-style"
          {...config}
          toolbar={toolbar}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <InlineError message={error} />
    </BlockStack>
  );
};

export default EditorComponent;
