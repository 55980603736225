import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Badge, BlockStack, Icon, IndexTable, InlineStack, Link, Page, Text } from "@shopify/polaris";
import { ChatIcon, ClipboardIcon } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { getCountryCallingCode } from "libphonenumber-js";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { ProfileContext } from "@/Context/ProfileContext";
import { getFilterField } from "../../Assets/Mocks/User.mock";

function DeletedUser() {
  const profileData = useContext(ProfileContext);
  const childRef = useRef();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
  };
  const getTrialDays = () => {
    const trial = {
      days: profileData?.trial_days,
      start: !profileData?.trial_start ? new Date() : profileData?.trial_start,
    };
    const dt2 = new Date();
    const dt1 = new Date(trial.start);
    const remainingDays =
      trial.days -
      Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    return remainingDays > 0 ? remainingDays : 0;
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const handleIconClick = (phoneNumber, countryCode) => {
    const callingCode = getCountryCallingCode(countryCode);
    const whatsappLink = `https://wa.me/${callingCode}${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const filterFormFields = useMemo(() => getFilterField(), []);

  const ArchivedData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      const featureStatus = row?.featureStatus;
      const feedbackArray = featureStatus ? Object.keys(featureStatus) : [];
      const filteredItems = feedbackArray.filter((item) => item !== "brokenLinksEnableDate");
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="100">
              <Link url={`https://${row.shopUrl}`} target="_blank">
                {row.storeName}
              </Link>
              <InlineStack gap="200">
                <Text>{row?.shopUrl}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.shopUrl)}>
                  <Icon source={ClipboardIcon} />
                </a>
              </InlineStack>
              <Text>{row?.shop_owner}</Text>
              <BlockStack gap="100">
                {Array.isArray(row?.feedback) &&
                  row?.feedback?.map((item, index) => <b key={index}> {item.id}</b>)}
              </BlockStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>{row?.country_name}</Text>
              <Text>Currency:{row.currency}</Text>
              <InlineStack gap="200">
                <Text>{row?.email}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.email)}>
                  <Icon source={ClipboardIcon} />
                </a>
              </InlineStack>
              {row.phone && (
                <InlineStack gap="200">
                  <Text>{row?.phone}</Text>
                  <a style={{ cursor: "pointer" }} onClick={() => handleIconClick(row.phone, row.country_code)}>
                    <Icon source={ChatIcon} />
                  </a>
                </InlineStack>
              )}
              <Text>Product Count : {row?.productCount}</Text>
              <Text>Installed: {formatTimeAgo(row?.created)}</Text>
              {row.created_at ? <Text>Created : {formatTimeAgo(row?.created_at)}</Text> : null}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>App Plan : {row?.recurringPlanName}</Text>
              <Text>Free Trial: {getTrialDays()} days</Text>
              <Text>Shopify Plan : {row?.plan_display_name}</Text>
              <Text>
                Onboard:
                {row.isOnBoardingDone ? <Badge tone="success">Y</Badge> : <Badge tone="warning">N</Badge>}
              </Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100" key="statusKey">
              {filteredItems.map((item, index) => (
                <Badge key={index} tone={featureStatus[item] === false ? "critical" : "success"}>
                  {item}
                </Badge>
              ))}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text> {row?.deleted ? formatTimeAgo(row.deleted) : ""}</Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="500">
        <CommonTable
          resourceName={{
            singular: "User",
            plural: "Users",
          }}
          title="Deleted User"
          queryPlaceholder="Search User by (shopUrl, email, storeName, recurringPlanId, recurringPlanName)"
          url={`admin/deleteduser`}
          selectable={false}
          rowsData={ArchivedData}
          isFilterVisible
          headings={[
            { title: "No" },
            { title: "Name" },
            { title: "Email" },
            { title: "Plan" },
            { title: "status" },
            { title: "Access" },
            { title: "Actions" },
          ]}
          searchKey={["storeName", "shopUrl", "email", "recurringPlanId", "recurringPlanName"]}
          ref={childRef}
          isAdd={false}
          verticalAlign="center"
          columnContentTypes={["text", "text", "numeric", "numeric", "numeric", "text", "text"]}
          filterFormFields={filterFormFields}
          pinnedFilter={["recurringPlanType", "isOnBoardingDone", "reviewRequest", "productCount"]}
        />
      </BlockStack>
    </Page>
  );
}

export default DeletedUser;
