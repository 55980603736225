import React from "react";
import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonTabs,
  SkeletonThumbnail,
  Text,
} from "@shopify/polaris";

const PricePage = ({ elements, isPremium }) => {
  return (
    <>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "16px",
                height: process.env.ISFREEAPP == 1 ? "100%" : "43.5rem",
              }}
            >
              <Card>
                <div style={{ width: "18rem" }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ width: !isPremium ? "3rem" : "5rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {!isPremium ? (
                        <div className="pricing-SkeletonThumbnail">
                          <SkeletonThumbnail size="extraSmall" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <div className="pricing-SkeletonBodyText">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "3rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {isPremium ? (
                        <div style={{ width: "8rem" }}>
                          <SkeletonBodyText lines={1} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    {isPremium ? (
                      <div
                        className="pricing-SkeletonBodyText premium-page--skeleton"
                        style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                      >
                        <div className="premium-skeleton">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    {isPremium ? (
                      <div className="premium-skeleton--page">
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <SkeletonBodyText lines={1} />
                    <br />
                    <div>{elements}</div>
                    <br />
                    {process.env.ISFREEAPP == 1 ? (
                      <div className="pricing-SkeletonDisplayText">
                        <SkeletonDisplayText size="medium" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </>
  );
};

export function CommonSkeletonPage() {
  return (
    // <SkeletonPage primaryAction>
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={1} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function DashboardSkeleton({ isEnabled }) {
  const hideReview = localStorage.getItem("SeasonalReview");
  const hideOtherApps = localStorage.getItem("SeasonalOtherApps");
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {!isEnabled && isEnabled != undefined && (
              <Card>
                <SkeletonBodyText lines={2} />
                <br />
                <SkeletonDisplayText size="small" />
              </Card>
            )}

            <Card>
              <SkeletonTabs count={5} />
              <br />
              <SkeletonBodyText lines={3} />
              <br />
              <SkeletonBodyText lines={3} />
              <br />
              <SkeletonBodyText lines={3} />
              <br />
              <SkeletonDisplayText size="small" />
            </Card>

            {!hideReview && (
              <Card>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <div className="hideReview--skeleBodyOne">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="hideReview--skeleBodySecond">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ width: "8rem", marginTop: "1rem" }}>
                      <SkeletonDisplayText />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ marginRight: "4rem" }}>
                      <SkeletonThumbnail size="large" />
                    </div>
                    <div className="hideReview--remove">
                      <SkeletonBodyText lines={1} />
                    </div>
                  </div>
                </div>
              </Card>
            )}

            {!hideOtherApps && (
              <Card>
                <div className="seasonal-other-apps">
                  <InlineStack align="space-between">
                    <div className="seasonal--other-apps--title">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="seasonal-discard">
                      <SkeletonBodyText lines={1} />
                    </div>
                  </InlineStack>
                </div>
                <br />
                <div className="otherApp">
                  <Card>
                    <InlineStack wrap={false} gap={500} align="start">
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <SkeletonThumbnail size="large" />
                        <div className="other-app-content">
                          <div className="other-app--name">
                            <SkeletonBodyText lines={2} />
                          </div>
                          <div className="other-app--text">
                            <div className="other-app-text--one">
                              <SkeletonBodyText lines={1} />
                            </div>
                            <div className="other-app-text--two">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <SkeletonDisplayText size="small" />
                        </div>
                      </div>
                    </InlineStack>
                  </Card>
                  <Card>
                    <InlineStack wrap={false} gap={500} align="start">
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <SkeletonThumbnail size="large" />
                        <div className="other-app-content">
                          <div className="other-app--name">
                            <SkeletonBodyText lines={2} />
                          </div>
                          <div className="other-app--text">
                            <div className="other-app-text--one">
                              <SkeletonBodyText lines={1} />
                            </div>
                            <div className="other-app-text--two">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <SkeletonDisplayText size="small" />
                        </div>
                      </div>
                    </InlineStack>
                  </Card>
                </div>
                <br />
                <div className="seasonal-show-more--apps">
                  <SkeletonDisplayText size="small" />
                </div>
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function AdvanceSettingsSkeleton() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card>
              <div style={{ width: "6rem" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <br />
              <SkeletonBodyText lines={3} />
              <br />
              <div style={{ width: "8rem" }}>
                <SkeletonBodyText lines={1} />
              </div>
              <br />
              <SkeletonBodyText lines={3} />
              <br />
              <SkeletonDisplayText size="small" />
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export function PricingSkeleton() {
  const elements = [];
  for (let i = 0; i < 9; i++) {
    elements.push(
      <div key={`div-${i}`} style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <div className="pricing-SkeletonThumbnail">
          <SkeletonThumbnail size="extraSmall" />
        </div>
        <div style={{ width: "10rem" }}>
          <SkeletonBodyText lines={1} />
        </div>
      </div>
    );
    if (i !== 8) {
      elements.push(<br key={`br-${i}`} />);
    }
  }
  let isPremium = true;
  return (
    <>
      <SkeletonPage primaryAction>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap" }}>
          <PricePage elements={elements} />
          {process.env.ISFREEAPP == 0 ? <PricePage elements={elements} isPremium={isPremium} /> : ""}
        </div>
      </SkeletonPage>
    </>
  );
}
