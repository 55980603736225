export const formFields = [
  {
    id: "name",
    name: "name",
    label: "Name",
    validated: false,
    type: "select",
    options: [
      { label: "", value: "" },
      { label: "Country_code", value: "country_code" },
      { label: "Country_name", value: "country_name" },
      { label: "Currency", value: "currency" },
      { label: "OnBoardingDone ", value: "isOnBoardingDone" },
      { label: "plan_display_name", value: "plan_display_name" },
      { label: "Plan_name ", value: "plan_name" },
      { label: "ProductCount", value: "productCount" },
      { label: "RecurringPlanName ", value: "recurringPlanName" },
      { label: "RecurringPlanType", value: "recurringPlanType" },
      { label: "StepCount", value: "stepCount" },
      { label: "Created_at", value: "created_at" },
      { label: "Trial_days", value: "trial_days" },
      { label: "Trial_start", value: "trial_start" },
      { label: "Installed", value: "created" },
      { label: "LastLogin", value: "lastLogin" },
      { label: "reviewRequest", value: "reviewRequest.isReviewPosted" },
      { label: "recurringPlanId", value: "recurringPlanId" },
    ],
  },
];
export const initialValues = {
  name: "",
};
