import React, { useCallback, useRef, useState } from "react";
import { BlockStack, Button, ButtonGroup, List } from "@shopify/polaris";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { formFields, initialValues } from "@/Assets/Mocks/GetData.mock";
import CommonForm from "@/Components/Common/CommonForm";

function GetData() {
  const formRef = useRef();
  const fetch = useAuthenticatedFetch();
  const [responseData, setResponseData] = useState([]);

  const handleSubmit = useCallback(async (value) => {
    const response = await fetch.post(`admin/distinctData`, { ...value });
    setResponseData(response.data);
  }, []);
  const submitForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  return (
    <div className="getData" style={{ padding: "40px" }}>
      <BlockStack gap="500">
        <CommonForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          formFields={formFields}
          title="searchUserQuery"
          formRef={formRef}
          isSave={false}
        />
        <ButtonGroup>
          <Button variant="primary" tone="success" onClick={submitForm} medium>
            Search
          </Button>
        </ButtonGroup>
        <List type="number">
          {responseData.map((item, index) => (
            <List.Item key={index}>{item}</List.Item>
          ))}
        </List>
      </BlockStack>
    </div>
  );
}

export default GetData;
