import * as ReactDOMClient from "react-dom/client";
import ReactGA from "react-ga4";
import { logger } from "@/Services/Logger/Index";
import App from "./App";

if (process.env.REACT_APP_GA_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

logger.init({});

const root = ReactDOMClient.createRoot(document.getElementById("app"));
root.render(<App />);
