import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Icon,
  Image,
  InlineError,
  InlineStack,
  RadioButton,
  RangeSlider,
  Select,
  Text,
  TextField,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { DeleteIcon, PlusIcon, ProductIcon } from "@shopify/polaris-icons";
import { Field, FieldArray, useFormikContext } from "formik";
import { t } from "i18next";
import { PremiumSvg } from "@/Assets/Index";
import { navigate } from "@/Components/Common/NavigationMenu";
import { ProfileContext } from "@/Context/ProfileContext";
import { getValueFromNestedObject, isObject } from "@/Utils/Index";
import Codemirror from "./CodeMirror";
import ColorPickerCircle from "./ColorPicker";
import EditorComponent from "./EditorComponent";
import Fontpicker from "./FontPicker";
import HelpTextSelector from "./HelpTextSelector";
import ImagePicker from "./ImagePicker";
import LoctionPostion from "./LoctionPostion";
import MultiSelect from "./MultiSelect";
import { PremiumButton } from "./PremiumBadge";
import SearchAutocomplete from "./SearchAutocomplete";
import SearchableSelect from "./SearchableSelect";
import Switch from "./Switch";

const timeRegex = /^(([0-1]?[0-9]|2[0-3]):)?([0-5]?[0-9]:)?([0-5]?[0-9])?$/;

const Delete = (props) => {
  if (props.minimum > props.index) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div style={{ textAlign: "right" }}>
      <Button variant="" onClick={() => props.remove(props.index)}>
        <Icon source={DeleteIcon} />
      </Button>
    </div>
  );
};

const Add = (props) => {
  const { t } = useTranslation();

  return props.type !== "single" ? (
    <Button
      variant=""
      onClick={() => props.push(Object.fromEntries(props.subfields.map((subfield) => [subfield.name, ""])))}
    >
      <div style={{ display: "flex" }}>
        <Icon source={PlusIcon} />
        &nbsp;{t("common.FormComponent.Add ")}
        {props.label}
      </div>
    </Button>
  ) : (
    <Button variant="" onClick={() => props.push("")}>
      <div style={{ display: "flex" }}>
        <Icon source={PlusIcon} />
        &nbsp;{t("common.FormComponent.Add ")}
        {props.label}
      </div>
    </Button>
  );
};

export const prefixFn = (prefix) => {
  const { profileData } = useContext(ProfileContext);
  const [prefixVal, setPrefixVal] = useState();

  useEffect(() => {
    if (profileData) {
      setPrefixVal(eval(prefix));
    }
  }, [profileData]);

  return prefixVal;
};

const DependOn = (props) => {
  const {
    field,
    form: { values, setFieldValue },
  } = props;
  if (!field.dependOn) {
    return <FormField {...props} />;
  }
  const dependOnType = field.dependOn.type;

  // add for nested array fields depending
  let fieldsValues = values;
  if (field.dependOn.isArray) {
    fieldsValues = props.currentObj;
  }
  const dependencyFiledValue = getValueFromNestedObject(fieldsValues, field.dependOn.name);

  let valueMatch = false;
  if (Array.isArray(field.dependOn.value)) {
    for (let value of field.dependOn.value) {
      if (value === dependencyFiledValue) {
        valueMatch = true;
        break;
      }
    }
  } else {
    valueMatch = dependencyFiledValue === field.dependOn.value;
  }

  if (dependOnType === "hidden") {
    if (valueMatch) {
      return <FormField {...props} />;
    }
  }

  if (dependOnType === "disabled") {
    return <FormField {...props} disabled={valueMatch} />;
  }
};

export const PremiumIcon = (props) => {
  const { t } = useTranslation();

  const { label } = props;
  const setNavigate = navigate();
  const { profileData } = useContext(ProfileContext);

  const gotoPrice = () => {
    setNavigate("/pricing");
  };

  return profileData && profileData?.recurringPlanId === "Free" ? (
    <div className="premium_icon">
      <InlineStack gap="200">
        <div>{label}</div>
        <Tooltip
          content={
            <Text as="span" variant="bodyMd" fontWeight="bold">
              {t("common.FormComponent.These is premium feature")}
              <Button variant="" onFocus={gotoPrice}>
                {t("common.FormComponent.Go to pricing")}
              </Button>
            </Text>
          }
          preferredPosition="above"
        >
          <Image source={PremiumSvg} />
        </Tooltip>
      </InlineStack>
    </div>
  ) : (
    label
  );
};

const FormField = (props) => {
  const {
    field: {
      helpTextDynamic,
      helpText,
      helpTextFn,
      requiredIndicator,
      validated,
      pattern,
      type,
      onChange,
      name,
      key,
      showPremium,
      label,
      prefix,
      prefixDynamic,
    },
    form: { handleChange, handleBlur },
    error,
    value,
    touch,
    field,
  } = props;
  const fieldProps = {
    ...props.field,
    ...props,
    name,
    label: showPremium ? <PremiumIcon label={label} /> : label,
    value: value,
    helpText: helpTextDynamic ? helpTextFn(props) : helpText,
    prefix: prefixDynamic ? prefixFn(prefix) : prefix,
    requiredIndicator: JSON.stringify(requiredIndicator) ? requiredIndicator : validated,
    validate: props.field.customValidate,
    onChange: onChange
      ? (value) => {
          onChange(value, props.form);
        }
      : (value) => {
          if (!pattern || timeRegex.test(value) || value === "") {
            handleChange({ target: { name, value } });
          }
        },
    onBlur: () => handleBlur({ target: { name } }),
    error: error && touch && error,
  };

  if (type === "SearchAutocomplete") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={SearchAutocomplete} />
      </React.Fragment>
    );
  }

  if (type === "colorPicker") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={ColorPickerCircle} />
      </React.Fragment>
    );
  }

  if (type === "editor") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={EditorComponent} />
      </React.Fragment>
    );
  }

  if (type === "multiSelect") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={MultiSelect} />
      </React.Fragment>
    );
  }

  if (type === "searchableSelect") {
    return (
      <React.Fragment key={key}>
        <Field
          {...fieldProps}
          validate={(value) => {
            return searchableSelectValidation(value, props.field.options);
          }}
          component={SearchableSelect}
        />
      </React.Fragment>
    );
  }

  if (type === "rangeSlider") {
    const { showOutput = true, defaultSuffix = true } = fieldProps;
    if (defaultSuffix) {
      fieldProps.suffix = (
        <p
          style={{
            minWidth: "24px",
            textAlign: "right",
          }}
        >
          {fieldProps.suffix}
          {value}
        </p>
      );
    }

    return <RangeSlider {...fieldProps} output={showOutput} />;
  }

  if (type === "img") {
    return <Thumbnail source={props.value || ProductIcon} size={props.field.size} alt={props.name} />;
  }

  if (type === "switch") {
    // return <Switch {...fieldProps} checked={props.value} />;
    return <Switch {...fieldProps} />;
  }

  if (type === "checkbox") {
    return <Checkbox {...fieldProps} checked={props.value} />;
  }
  if (type === "array") {
    return (
      <FieldArray key={field.id} name={name}>
        {({ push, remove }) => (
          <React.Fragment key={field.id}>
            <BlockStack gap="400">
              <Text as="p">{field.label}</Text>
              {value?.map((value, index) => (
                <div key={index}>
                  {!field.hideDeletebtn && <Delete {...field} index={index} remove={remove} />}
                  <Field
                    name={`${name}.[${index}]`}
                    field={{
                      ...props.field,
                      type: props.field.arrayType,
                      name: `${name}.[${index}]`,
                    }}
                    component={FormField}
                    value={value}
                    error={error?.[index] ? error?.[index] : false}
                    touch={touch?.[index] ? touch?.[index] : false}
                  />
                </div>
              ))}
              {!field.hideAddbtn && <Add {...field} push={push} type="single" />}
            </BlockStack>
          </React.Fragment>
        )}
      </FieldArray>
    );
  }

  if (type === "radio") {
    return (
      <RadioButton
        {...fieldProps}
        id={props.field.radioId}
        checked={value === props.field.radioId}
        onChange={(booleanType, value) => {
          props.onChange && props.onChange(value, props.form);
          if (!pattern || timeRegex.test(value) || value === "") {
            handleChange({ target: { name, value } });
          }
        }}
      />
    );
  }

  if (type === "select") {
    return <Select {...fieldProps} />;
  }
  if (type === "helpTextSelector") {
    return (
      <React.Fragment key={key}>
        <Field
          {...fieldProps}
          component={HelpTextSelector}
          validate={(value) => {
            if (props.field.validated) {
              if (value.replace(/(<([^>]+)>)/gi, "").trim()) {
                return false;
              } else {
                return props.field.errormsg;
              }
            } else {
              return false;
            }
          }}
        />
      </React.Fragment>
    );
  }

  if (type === "imagePicker") {
    return (
      <React.Fragment key={key}>
        <Field
          {...fieldProps}
          validate={(value) => {
            if (!value) {
              value = [];
            }
            if (isObject(value)) {
              value = [value];
            }
            if (props.field.min && props.field.max) {
              if (props.field.min > value.length || value.length > props.field.max) {
                return `${t("common.FormComponent.Minimum")} ${props.field.min} ${t(
                  "common.FormComponent.and"
                )} ${t("common.FormComponent.Maximum")} ${props.field.max} ${t(
                  "common.FormComponent.images are required"
                )}`;
              }
            } else if (props.field.min) {
              if (props.field.min > value.length) {
                return `${t("common.FormComponent.Minimum")} ${props.field.min} ${t(
                  "common.FormComponent.images are required"
                )}`;
              }
            } else if (props.field.max) {
              if (value.length > props.field.max) {
                return `${t("common.FormComponent.Maximum")} ${props.field.max} ${t(
                  "common.FormComponent.images are allowed"
                )}`;
              }
            }
            return false;
          }}
          component={ImagePicker}
        />
      </React.Fragment>
    );
  }

  if (type === "codeMirror") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={Codemirror} />
      </React.Fragment>
    );
  }

  if (type === "fontpicker") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={Fontpicker} />
      </React.Fragment>
    );
  }

  if (type === "locationPosition") {
    return (
      <React.Fragment key={key}>
        <Field {...fieldProps} component={LoctionPostion} />
      </React.Fragment>
    );
  }
  if (type === "choiceList") {
    return <ChoiceList {...fieldProps} selected={props.value} />;
  }
  return <TextField {...fieldProps} />;
};

const Error = ({ name }) => {
  const { errors, touched } = useFormikContext();
  const error = errors[name];
  const touch = touched[name];
  return touch && error ? <InlineError message={error} fieldID={name} /> : null;
};

const SaveButton = ({ isSave = true, isPremium = false, label = t("dashboard.Save") }) => {
  if (isSave) {
    if (isPremium) {
      return (
        <PremiumButton>
          {/* <Button variant="primary" tone="success" submit> */}
          <Button variant="primary" submit>
            {label}
          </Button>
        </PremiumButton>
      );
    } else {
      return (
        // <Button variant="primary" tone="success" submit>
        <Button variant="primary" submit>
          {label}
        </Button>
      );
    }
  }
};

const searchableSelectValidation = (value, options) => {
  if (options.findIndex((option) => option.value === value) === -1) {
    return "Select valid option.";
  }
  return false;
};

const DynamicSection = ({ children, field }) => {
  if (field.section !== undefined && !field.section) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <Card padding="400">{children}</Card>;
  }
};

export { Delete, Add, FormField, Error, SaveButton, DynamicSection, DependOn };
