import { t } from "i18next";

export const getNavigationLinks = () => [
  // {
  //   label: t("dashboard.Dashboard"),
  //   destination: "/",
  // },
  {
    label: t("advanceSettings.Advance Settings"),
    destination: "/advance-settings",
  },
  {
    label: t("pricing.Price"),
    destination: "/pricing",
  },
  {
    label: t("faqs.Faqs"),
    destination: "/Faqs",
  },
  {
    label: t("settings.Profile"),
    destination: "/settings",
  },
  // {
  //   label: t("mocks.navigation.Other"),
  //   destination: "/Other",
  // },
  // {
  //   label: t("mocks.navigation.Feedback"),
  //   destination: "/feedback",
  // },
];
