import React, { createContext, useCallback, useState } from "react";
import { Toast } from "@shopify/polaris";

export const ToastContext = createContext();
export const ToastContextProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [isErrorToast, setIsErrorToast] = useState(false);

  const showToast = useCallback((err, isError) => {
    setToastMessage(err);
    setIsToastVisible(true);
    setIsErrorToast(isError || false);
  }, []);

  const hideToast = useCallback(() => {
    setIsToastVisible(false);
    setIsErrorToast(false);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {isToastVisible && <Toast error={isErrorToast} content={toastMessage} onDismiss={hideToast} />}
    </ToastContext.Provider>
  );
};
