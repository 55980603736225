import { ImageIcon, LinkIcon, ProductIcon, SearchIcon } from "@shopify/polaris-icons";
import { t } from "i18next";

export const getStaticSuggestedItems = () => [
  {
    icon: ImageIcon,
    color: "success",
    title: t(`suggest.Optimize Images`),
    description: t(`suggest.Improve website performance by optimizing images.`),
    navigate: "/imageoptimization",
    tabindex: 2,
  },
  {
    icon: ProductIcon,
    color: "info",
    title: t(`suggest.Generate Meta tags`),
    description: t(`suggest.Enhance SEO with automatically generated meta tags.`),
    navigate: "/metatags",
    tabindex: 1,
  },
];

export const getDynamicSuggestedItems = () => [
  {
    id: "google_page_speed",
    icon: LinkIcon,
    color: "warning",
    title: t(`suggest.Enable Google page speed`),
    description: t(`suggest.Boost website speed by enabling Google Page Speed.`),
    navigate: "/speed-optimize",
    type: "appStatus",
  },
  {
    id: "gsc",
    icon: SearchIcon,
    color: "warning",
    title: t(`suggest.Connect GSC`),
    description: t(`suggest.Enhance search visibility by connecting to Google Search Console.`),
    navigate: "/google-search-console",
    type: "dynamic",
  },
  {
    id: "ImageOptimization",
    icon: ImageIcon,
    color: "success",
    title: t(`suggest.Enable Image Optimize Automation`),
    description: t(`suggest.Streamline image optimization with automated processes.`),
    navigate: "/imageoptimization",
    type: "automation",
    tabindex: 3,
  },
  {
    id: "MetaProductCreate",
    icon: ProductIcon,
    color: "info",
    title: t(`suggest.Automate Meta tags`),
    description: t(`suggest.Improve SEO by automating the generation of meta tags.`),
    navigate: "/metatags",
    type: "automation",
    tabindex: 2,
  },
  {
    id: "brokenLinks",
    icon: LinkIcon,
    color: "warning",
    title: t(`suggest.Automate Broken Links`),
    description: t(`suggest.Improve UX with automated detect and handle, broken links.`),
    navigate: "/brokenlink",
    type: "automation",
    tabindex: 4,
  },
];
