import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Text } from "@shopify/polaris";
import ErrorImg from "@/Assets/Images/Error.png";

export default function NoAdminAccess() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
        width: "100%",
      }}
    >
      <br />
      <img src={ErrorImg} alt="error" style={{ width: "200px" }} />
      <br />
      <Text variant="heading2xl" as="h2" tone="critical">
        {t("common.NoAdminAccess.Oops! Something went wrong. Admin token is malformed")}
      </Text>
      <br />
      <Text variant="headingMd" as="h4" tone="caution">
        {t("common.NoAdminAccess.Let's try that again, please refresh the page.")}
      </Text>
      <Text variant="headingMd" as="h4" tone="caution">
        {t("common.NoAdminAccess.If you're still stuck, contact")}&nbsp;
        <Link url="mailto:dakaasapps@gmail.com" target="_blank">
          {/* {t("common.NoAdminAccess.support@webrexstudio.com")} */}
          dakaasapps@gmail.com
        </Link>
        &nbsp;{t("common.NoAdminAccess.so we can help!")}
      </Text>
    </div>
  );
}
